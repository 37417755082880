import React from "react"
import { graphql, PageProps } from "gatsby"
import { Parallax, ParallaxProvider } from "react-scroll-parallax"
import LayoutContainer from "@containers/Layout"
import SEO from "@components/SEO"
import Hero from "@components/LoloFranchise/Hero"
import AboutFeatures from "@components/LoloFranchise/AboutFeatures"
import WhatYouGet from "@components/LoloFranchise/WhatYouGet"
import CtaSection from "@components/LoloFranchise/CtaSection"
import Faq from "@components/LoloFranchise/FaqSection"
import Footer from "@components/UI/Footer"
import Circle from "@components/Circle"
import { WhatIsPageDataType } from "@typings/requests/partners/lolo-franchise"
import "./lolo-franchise.scss"

const WhatIsPage: React.FC<PageProps<WhatIsPageDataType>> = props => {
  const {
    allStrapiWhatIsPage: {
      nodes: [loloFranchiseData],
    },
    allStrapiPageFooter: {
      nodes: [footerData],
    },
  } = props.data
  const { hero, features, whatYouGet, cta, faq } = loloFranchiseData

  return (
    <>
      <SEO
        title={loloFranchiseData.title}
        description={loloFranchiseData.description}
        pathname={props.location.pathname}
        lang={loloFranchiseData.locale}
        image={loloFranchiseData.metaImage.url}
      />
      <ParallaxProvider>
        <LayoutContainer
          className="about-page"
          location={props.location}
          lang="es"
          isPartnerSubdomain
        >
          <div className="about-page__bg-gradient"></div>
          <Hero {...hero} />
          <AboutFeatures
            {...features}
            countriesImage={
              features.countriesImage.localFile.childImageSharp.gatsbyImageData
            }
            brandImage={
              features.brandImage.localFile.childImageSharp.gatsbyImageData
            }
            appsImage={
              features.appsImage.localFile.childImageSharp.gatsbyImageData
            }
          />
          <WhatYouGet
            {...whatYouGet}
            allinoneImage={
              whatYouGet.allinoneImage.localFile.childImageSharp.gatsbyImageData
            }
            thresholdImage={
              whatYouGet.thresholdImage.localFile.childImageSharp
                .gatsbyImageData
            }
            allinoneImageMobile={
              whatYouGet.allinoneImageMobile.localFile.childImageSharp
                .gatsbyImageData
            }
            brandImage={
              whatYouGet.brandImage.localFile.childImageSharp.gatsbyImageData
            }
          />
          <CtaSection
            {...cta}
            image={cta.image.localFile.childImageSharp.gatsbyImageData}
          />
          <Faq {...faq} />
          <div className="about-footer__wrapper">
            <div className="container about-footer__container">
              <Footer className="about-page__footer" {...footerData} />
              <div className="about-footer-circle about-footer-circle--big-blue">
                <Parallax translateY={[35, -35]}>
                  <Circle color="#431B93" />
                </Parallax>
              </div>
              <div className="about-footer-circle about-footer-circle--small-green">
                <Parallax translateY={[55, -55]}>
                  <Circle color="#009B3A" />
                </Parallax>
              </div>
            </div>
          </div>
        </LayoutContainer>
      </ParallaxProvider>
    </>
  )
}

export const query = graphql`
  {
    allStrapiWhatIsPage(filter: { locale: { eq: "es" } }) {
      nodes {
        title
        description
        cta {
          title
          buttonLink: button {
            label
            url
          }
          id
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 423, quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
        faq {
          faqItem {
            id
            answer {
              data {
                answer
              }
            }
            question
          }
          title
        }
        features {
          appsDescription
          appsTitle
          brandDescription
          brandTitle
          countriesDescription
          countriesTitle
          successDescription
          successTitle
          title
          countriesImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 344
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
              }
            }
          }
          brandImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 573
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
              }
            }
          }
          appsImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 383
                  layout: CONSTRAINED
                  placeholder: DOMINANT_COLOR
                )
              }
            }
          }
        }
        hero {
          buttonLink: button {
            label
            url
          }
          description
          title
        }
        metaImage {
          url
        }
        whatYouGet {
          title
          thresholdTitle
          thresholdDescription
          successTitle
          successDescription
          storesTitle
          storesDescription
          marketingTitle
          marketingDescription
          launchTitle
          launchDescription
          brandTitle

          allinoneTitle
          brandDescription
          allinoneDescription
          allinoneImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 614, quality: 90, layout: CONSTRAINED)
              }
            }
          }
          allinoneImageMobile {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 338
                  height: 411
                  quality: 90
                  layout: FIXED
                )
              }
            }
          }
          brandImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 574, quality: 90, layout: CONSTRAINED)
              }
            }
          }
          thresholdImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 678, quality: 90, layout: CONSTRAINED)
              }
            }
          }
          allinoneButton {
            id
            label
            url
          }
        }
      }
    }
    allStrapiPageFooter(filter: { locale: { eq: "es" } }) {
      nodes {
        title
        socialLink {
          name
          title
          url
        }
      }
    }
  }
`

export default WhatIsPage
